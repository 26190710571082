import { Fragment, memo, useEffect, useState } from "react";
import SectionSlider from "../slider/SectionSlider";
import ContinueWatchCard from "../cards/ContinueWatchCard";
import Translate from "../MultiLanguage/Translate";

const ContinueWatching = memo(({ data }) => {
  const [title] = useState(<Translate textKey={'continue_watching'} />);
  const [watching, setWatching] = useState([]);

  useEffect(() => {
    setWatching(data?.shows);

  }, [data]);

  return (
    <Fragment>
      <SectionSlider
        title={title}
        list={watching}
        className="continue-watching-block section-padding-top bottom_margin_space continueWatching continueWatching-bottom"
        slidesPerView={5}
        data={data}
        styleElement={false}
      >
        {(data) => (
          <ContinueWatchCard
            imagePath={data.logo_thumb}
            progressValue={data.watched_percentage}
            dataLeftTime={data.show_name}
            watchedLength={data?.watched_duration}
            link={`/videos/${data?.video_vanity_url}`}
          ></ContinueWatchCard>
        )}
      </SectionSlider>
    </Fragment>
  );
});

ContinueWatching.displayName = ContinueWatching;
export default ContinueWatching;
