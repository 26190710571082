import React, { useEffect, useState } from "react";
// import EmailForm from "./EmailForm";
import { sendEmailLoginRequest } from "../../../Screens/Accounts/Login/service";
import { toast ,ToastContainer} from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import VerifyEmail from "./VerifyEmail";
import Translate from "../../MultiLanguage/Translate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const LoginModal = ({ isEmailForm, setEmailLoginModal, tvCode }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [open, setOpen] = React.useState(isEmailForm);
  const [inputValue, setInputValue] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [id, setId] = useState()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    setOpen(isEmailForm);
  }, [isEmailForm]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseEmailForm = (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setOpen(false);
    setTimeout(() => {
      setEmailLoginModal(false);
    }, 500);
  };

  const emailHandler = (e) => {
    e.preventDefault();
    let emailRegex = /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!inputValue) {
      toast.error("Field cannot be empty!", {
        position: "bottom-center",
      });
    } else {
      if (!inputValue?.match(emailRegex)) {
        toast.error("Enter a valid email!", {
          position: "bottom-center",
        });
      } else {
        emailLoginRequest();
      }
    }
  };

  const emailLoginRequest = async () => {
    try {
      const response = await sendEmailLoginRequest(appInfo, inputValue, tvCode);
      if (response?.status === 200) {
        toast.success(response?.data?.message, {
          position: "bottom-center",
        });
        setIsEmailSent(true);
        setId(response?.data?.data?.id)
        // handleCloseEmailForm()
      }
      toast.error(response?.message, {
        position: "bottom-center",
      });
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };
  return (
    <div className="loginModal">
      <ToastContainer />
      {
        isEmailSent ? (
          <VerifyEmail inputValue={inputValue} appInfo={appInfo} id={id} isEmailSent={isEmailSent} />

        ) :
          (

            <Dialog
              open={open}
              TransitionComponent={Transition}
              onClose={handleCloseEmailForm}
              aria-describedby="alert-dialog-slide-description"
              transitionDuration={500}
              className="emailForm"
              maxWidth="xl"
              id="loginEmailDialog"
            >
              <div style={{ textAlign: 'right' }}><span style={{ cursor: 'pointer', float: 'right', marginRight: '10px' }}
                onClick={() => {
                  setOpen(false); setTimeout(() => {
                    setEmailLoginModal(false)
                  }, 1000);
                }}
              >x</span></div>
              <h1>
                <img src={projectInfo?.projectConfig?.config?.LOGO} alt="" />
              </h1>
              <h2><Translate textKey={'sign_in'} /></h2>
              <DialogContent>
                <form>
                  <label htmlFor="email"><Translate textKey={'email_address'} /></label>
                  <input
                    type="email"
                    name="email"
                    value={inputValue || ""}
                    onChange={(e) => setInputValue(e.target.value)}
                    id="email"
                  />
                  <div className="buttonContainer" onClick={(e) => emailHandler(e)}>
                    <div className="background"></div>
                    <button type="submit"><Translate textKey={'next'} /></button>
                  </div>
                </form>
              </DialogContent>
            </Dialog>
          )
      }
    </div>
  );
};

export default LoginModal;
